<template>
  <div class="container-main">
    <div class="title-vdo-01">প্রাইভেসি পলিসি</div>

    <div class="abt-us">
      <p>
        নিম্নলিখিত নীতিমালার মাধ্যমে আপনার ব্যক্তিগত তথ্য আমাদের কাছে সংরক্ষিত
        থাকে।
      </p>
      <p>
        <label class="lblbold">১. ভূমিকা এবং সাধারণ শর্তাবলী</label><br />
        হুল্লোড় এর সেবাসমুহ ব্যবহারের সময় আপনি এবং আপনার পরিবারের বিভিন্ন
        তথ্যের গোপনীয়তা রক্ষায় হুল্লোড় প্রতিশ্রুতিবদ্ধ। আমরা এই ওয়েবসাইট
        ব্যবহারকারীদের জন্য নিরাপদ সেবা এবং আনন্দময় পরিবেশ নিশ্চিত করতে চাই।
        হুল্লোড় এর ওয়েবসাইটে আপনার দেওয়া ব্যক্তিগত যেকোনো তথ্য আমরা কীভাবে
        ব্যবহার করব, সে বিষয়ে এই গোপনীয়তার নীতিমালায় ধারণা দেওয়া হয়েছে।<br />

        আপনার কাছে হুল্লোড় এর সবগুলো সেবা নিশ্চিত করতে আপনার ব্যক্তিগত কিছু তথ্য
        আমাদের কখনও কখনও প্রয়োজন হতে পারে।<br />

        এই তথ্য গোপনীয়তার নীতিমালায় বর্ণিত বিষয়গুলো নিম্নরুপঃ<br />
        আপনার বিষয়ে হুল্লোড় কী ধরনের তথ্য সংগ্রহ করতে পারে আপনার কাছ থেকে
        সংগৃহীত তথ্যগুলো হুল্লোড় কীভাবে ব্যবহার করবে, আপনার সাথে যোগাযোগ করার
        জন্য কখন হুল্লোড় আপনার বিস্তারিত তথ্যগুলো ব্যবহার করতে পারে, আপনার
        বিস্তারিত তথ্যগুলো হুল্লোড় কী অন্য কারো কাছে প্রকাশ করতে পারে আমাদের
        কাছে দেওয়া ব্যক্তিগত তথ্যগুলোর বিষয়ে আপনার সিদ্ধান্ত, হুল্লোড়
        ওয়েবসাইটে কুকির ব্যবহার এবং আপনি কীভাবে এই কুকিগুলো বাতিল করতে
        পারবেন।<br />

        উপরের ভূমিকা অনুসারে হুল্লোড় আপনার ব্যক্তিগত তথ্যের নিরাপত্তা প্রদানে
        প্রতিশ্রুতিবদ্ধ। আপনি যখনই সে ধরনের কোন তথ্য প্রদান করছেন, আমরা তথ্য
        সংরক্ষণ আইন ১৯৯৮ সহ ব্যক্তিগত তথ্যের নিরাপত্তা সম্বন্ধীয় সকল আইন মোতাবেক
        আপনার তথ্যগুলো আইনগতভাবে ব্যবহার করতে বাধ্য থাকি(এই তথ্য গোপনীয়তার
        নীতিমালায় এই আইনগুলো একত্রিতভাবে “তথ্য সংরক্ষণ আইনসমূহ” হিসেবে বর্ণিত
        হয়েছে)।<br />

        এই ওয়েবসাইটে তৃতীয় পক্ষের মালিকানাধীন এবং পরিচালিত অন্য ওয়েবসাইটের
        কিছু হাইপারলিংক আছে। তৃতীয় পক্ষের এসব ওয়েবসাইটের নিজস্ব তথ্য
        গোপনীয়তার নীতিমালা এবং কুকি ব্যবহারের নিয়মাবলী আছে। আমাদের বিনীত
        অনুরোধ, আপনি সেগুলো পর্যবেক্ষণ করে নিন। এই ওয়েবসাইটগুলো ভিজিট করার সময়
        কুকিগুলোর দ্বারা সংগৃহীত বা আপনার প্রদানকৃত ব্যক্তিগত তথ্যগুলো সেসব
        নীতিমালার ভিত্তিতে পরিচালিত হবে। আমরা তৃতীয় পক্ষের সেসব ওয়েবসাইটের
        গোপনীয়তা সংক্রান্ত কোন দায়ভার গ্রহণ করব না এবং আপনি পুরোপুরি নিজ
        দায়িত্বে সেসব ওয়েবসাইট ব্যবহার করবেন।<br />
      </p>
      <p>
        <label class="lblbold"
          >২. আমার সম্পর্কে হুল্লোড় কী ধরনের তথ্য সংগ্রহ করবে?</label
        ><br />
        হুল্লোড় এর কোনো সার্ভিস পাওয়ার জন্য অথবা কোনো সার্ভিসে অংশ নেওয়ার
        জন্য, যেমনঃ নিউজলেটার বা অনলাইন রেজিস্ট্রেশন সিস্টেমে রেজিস্টার করার
        জন্য আপনি যখন সাইন আপ করবেন, তখন আমরা আপনার ব্যক্তিগত তথ্যগুলো জানতে
        চাইতে পারি। সেগুলো হতে পারে আপনার নাম, ইমেইল অ্যাড্রেস, পোস্টাল
        অ্যাড্রেস, টেলিফোন বা মোবাইল নম্বর অথবা জন্মতারিখ। ভিন্ন ভিন্ন পাতায়
        আপনাকে ব্যক্তিগত কিছু তথ্য দিতে হতে পারে।<br />

        অনুরোধের ভিত্তিতে বিভিন্ন স্থানে আপনার বিস্তারিত তথ্যসমূহ প্রদান করার
        মাধ্যমে আপনি হুল্লোড় এবং এর সেবা প্রদানকারীদের কাছ থেকে বিভিন্ন সেবা
        পাবেন।<br />

        হুল্লোড় কুকি এবং আইপি এ্যাড্রেসসমূহ সংগ্রহ করে থা্কে (আইপি অ্যাড্রেস হলো
        একটি নম্বর যা নির্দিষ্ট কোনো কম্পিউটার বা ইন্টারনেটের অন্যান্য
        নেটওয়ার্ক ডিভাইস শনাক্ত করতে পারে)। ব্যবহারকারী হিসেবে আপনার সুবিধা
        বৃদ্ধির উদ্দেশ্যে আইপি অ্যাড্রেস এবং কুকিসমূহ পর্যবেক্ষণের জন্য আমরা
        সফ্টওয়্যার ব্যবহার করে থাকি। এই তথ্যগুলো আপনার প্রোফাইলকে সমৃদ্ধ করার
        জন্য ব্যবহার করা হয় না এবং লগ ফাইলগুলো নিয়মিত মুছে ফেলা হয়।
      </p>
      <p>
        <label class="lblbold"
          >৩. আমার সম্পর্কে সংগৃহীত তথ্যগুলো হুল্লোড় কীভাবে ব্যবহার করবে?</label
        ><br />
        নিম্নে উল্লেখিত বিষয়সমূহসহ নির্দিষ্ট কিছু উদ্দেশ্যে হুল্লোড় আপনার
        ব্যক্তিগত তথ্যসমূহ ব্যবহার করবে: ‘সেবা প্রদানের প্রশাসনিক প্রয়োজন’-এ,
        এর অর্থ হলো, আপনি যেসব সেবা পাওয়ার জন্য সাইন আপ করেছেন, সেসব সেবা
        সম্বন্ধীয় কোনো প্রয়োজনে হুল্লোড় আপনার সঙ্গে যোগাযোগ করতে পারে (যেমনঃ
        আপনার পাসওয়ার্ড মনে করিয়ে দেওয়ার প্রয়োজনে অথবা রক্ষণাবেক্ষণের
        স্বার্থে বাতিল করা কোনো নির্দিষ্ট সেবা সম্পর্কে আপনাকে অবহিত করা)<br />

        এই ওয়েবসাইটে আপনার প্রদান করা কোনো উপাদানসহ যেকোনো কিছু প্রদানের বিষয়ে
        আপনার সঙ্গে যোগাযোগ করতেঃ<br />

        আপনার কাছে হুল্লোড় এর উপাদানসমূহ উপস্থাপনের কৌশল নির্ধারণ করতে আইপি
        অ্যাড্রেস ব্যবহার করে ব্যবহারকারীর অবস্থান নিশ্চিত করতে, বিভিন্ন দেশ
        থেকে কতবার ভিজিট করা হয়েছে তা নিশ্চিত হতে এবং সমস্যাজনক কর্মকান্ড বন্ধ
        করতে; এবং<br />

        হুল্লোড় এ উপস্থাপিত সেবা পর্যবেক্ষণ ও সেগুলো আরও সমৃদ্ধ করতে, যেমনঃ
        আপনাকে সবচেয়ে সহজে নেভিগেশনের সুবিধা দিতে উপরোক্ত কারণসমূহ ব্যতিত অন্য
        কোনো প্রয়োজনে হুল্লোড় কর্তৃক আপনার ব্যক্তিগত তথ্য ব্যবহারের আগে আমরা সে
        বিষয়ে আপনাকে অবশ্যই অবহিত করব। উপরের তালিকায় বর্ণিত প্রয়োজনসমূহ ছাড়া
        অন্য কোনো প্রয়োজনে আপনার তথ্যসমূহ ব্যবহারে আপনি অনুমতি নাও দিতে পারেন।
      </p>
      <p>
        <label class="lblbold">৪. হুল্লোড় কখন আমার সঙ্গে যোগাযোগ করবে?</label
        ><br />
        হুল্লোড় আপনার সঙ্গে যোগাযোগ করতে পারে:<br />
        আপনি যেসব সেবা পাওয়ার জন্য সাইন আপ করেছেন সেগুলোর প্রায়োগিক প্রয়োজনে
        এটা নিশ্চিত হওয়ার জন্য যে, সেসব সেবাসমূহ হুল্লোড় আপনাকে প্রদান করতে
        পারবে আপনি আরও কোনো চিঠিপত্র পাওয়ার ইচ্ছা প্রকাশ করে থাকলে হুল্লোড় এর
        সেবাসমূহ পর্যবেক্ষণের কোনো প্রক্রিয়ায় আপনাকে অংশগ্রহনের আমন্ত্রণ
        জানানোর জন্য (এ ধরনের অংশগ্রহন সবসময়ই স্বেচ্ছাসেবামূলক) এবং বিপণনের
        কোনো প্রয়োজনে, যেখানে আপনি নির্দিষ্টভাবে সম্মতি প্রদান করেছেন।
      </p>
      <p>
        <label class="lblbold"
          >৫. বিপণনের জন্য কি আমার সঙ্গে যোগাযোগ করা হবে?</label
        ><br />
        বিপণনের কাজে অথবা নতুন সেবাসমূহের উন্নয়নের কাজে হুল্লোড় কখনই আপনার
        সঙ্গে যোগাযোগ করবে না, যদি না আপনি এসব কাজে সম্পৃক্ত থাকার জন্য
        সুনির্দিষ্টভাবে সম্মতি প্রদান করেন।
      </p>
      <p>
        <label class="lblbold"
          >৬. আমার ব্যক্তিগত তথ্যগুলো হুল্লোড় কি অন্য কোথাও শেয়ার করবে?</label
        ><br />
        বাধ্যতামূলকভাবে প্রকাশ অথবা আইনী কোনো প্রয়োজনে (যেমনঃ কোনো প্রকার
        সরকারি বা আইন প্রয়োগকারী সংস্থার প্রয়োজনে) অথবা নিম্নে ৭ নম্বর
        পয়েন্টে বর্ণিত কারণসমূহ ছাড়া আমরা আপনার সকল তথ্য গোপন রাখব।
        সাধারণভাবে, আমরা শুধুমাত্র হুল্লোড় এর মধ্যেই আপনার তথ্যগুলো ব্যবহার করব।
        তবুও কখনও কখনও হুল্লোড় আপনার দেওয়া তথ্যগুলো প্রক্রিয়ার জন্য তৃতীয়
        পক্ষসমূহের কাছে প্রদান করে থাকে। সেক্ষেত্রে তৃতীয় পক্ষসমূহকে হুল্লোড় এর
        সকল নির্দেশনা কঠোরভাবে মানতে হবে এবং তারা হুল্লোড় কে নিশ্চিত করবে যে,
        তারা আপনার ব্যক্তিগত তথ্য তাদের নিজস্ব ব্যবসার প্রয়োজনে ব্যবহার করবে
        না।
      </p>
      <p>
        <label class="lblbold"
          >৭. হুল্লোড় এ অপ্রীতিকর বা অনুপোযুক্ত উপাদান</label
        ><br />
        আপনি যদি হুল্লোড় এ অথবা এর যেকোনো স্থানে কোনপ্রকার অপ্রীতিকর, অনুপোযুক্ত
        বা আপত্তিকর কিছু পাঠান অথবা হুল্লোড় এ কোনো প্রকার সংহতিনাশক কাজে জড়িত
        থাকেন, তবে হুল্লোড় আপনার ব্যক্তিগত তথ্য ব্যবহার করে আপনার সেসব আচরণ বন্ধ
        করবে।<br />

        সংগত কারণে হুল্লোড় যদি মনে করে আপনি বাংলাদেশের কোন আইন লঙ্ঘন করছেন বা
        করতে পারেন, তবে সে বিষয়ে হুল্লোড় আপনার ব্যক্তিগত তথ্য ব্যবহার করে
        সংশ্লিষ্ট তৃতীয় পক্ষসমূহকে, যেমনঃ আপনার নিয়োগদাতা, স্কুল,
        ইমেইল/ইন্টারনেট সংযোগ প্রদানকারী প্রতিষ্ঠান অথবা আইন প্রয়োগকারী
        সংস্থাকে সেসব উপাদান বা কর্মকান্ড সম্পর্কে অবহিত করবে।
      </p>
      <p>
        <label class="lblbold"
          >৮. ব্যবহারকারী হিসেবে আমার বয়স ১৬ বছর বা তার কম হলে কী হবে?</label
        ><br />
        আপনার বয়স যদি ১৬ বা তার কম হয়, তবে হুল্লোড় এর ওয়েবসাইটে আপনার
        ব্যক্তিগত তথ্য দেওয়ার আগে অনুগ্রহ করে আপনার বাবা-মা/অভিভাবকের অনুমতি
        নিন। এ ধরনের অনুমতি ছাড়া আপনি আমাদের কাছে আপনার ব্যক্তিগত তথ্য দিতে
        পারবেন না।
      </p>
      <p>
        <label class="lblbold"
          >৯. আমার ব্যক্তিগত তথ্যগুলো হুল্লোড় কতদিন সংরক্ষণ করবে?</label
        ><br />
        সংশ্লিষ্ট সেবার প্রয়োজনে আমাদের সিস্টেমে যতদিন প্রয়োজন হবে, অথবা যতদিন
        হুল্লোড় এর সঙ্গে আপনার কোনো প্রাসঙ্গিক চুক্তি থাকবে, ততদিন আমরা আপনার
        ব্যক্তিগত তথ্য সংরক্ষণ করব।<br />

        আবার, অন্য কোনো প্রকল্পের প্রয়োজনে আপনার তথ্য বা উপাদানগুলো দীর্ঘ
        সময়ের জন্য, এমনকি স্থায়ীভাবেও সংরক্ষণ করা হতে পারে।
      </p>
      <p>
        <label class="lblbold"
          >১০. আমি কি জানতে পারব, হুল্লোড় আমার ব্যক্তিগত কোন তথ্যগুলো সংরক্ষণ
          করছে?</label
        ><br />
        তথ্য নিরাপত্তা আইন মোতাবেক আপনার যেসব তথ্য হুল্লোড় সংরক্ষণ করে, সেগুলোর
        একটি প্রতিলিপি পাওয়ার বা সেগুলোর কোনো ভুল সংশোধনের অধিকার আপনার রয়েছে।
        (তথ্য পাওয়ার জন্য আপনাকে আপনার পরিচিতি নিশ্চিত করার জন্য আপনার ২ কপি
        সত্যায়িত ছবি দিতে হবে)। আমরা গুরুত্ত্ব সহকারে আমাদের কাছে থাকা আপনার
        ব্যক্তিগত তথ্যগুলোর যোগান দিব, অথবা সংশোধন করব বা মুছে ফেলব।<br />

        এ সংক্রান্ত কোনো আবেদন বা প্রশ্নের জন্য অথবা তথ্যের গোপনীয়তার এসব
        নীতিমালার বিষয়ে আপনার যদি কিছু জানার থাকে, তবে অনুগ্রহ করে যোগাযোগ
        করুন:<br />
        মোম্যাজিক বাংলাদেশ লিমিটেড<br />
        র‌্যাংগস বেবিলনিয়া, ২৪৬ বীর উত্তম মীর শওকত সড়ক<br />
        তেজগাঁও গুলশান লিঙ্ক রোড, ঢাকা ১২১২।
      </p>
      <p>
        <label class="lblbold"
          >১১. আমি যদি বাংলাদেশের বাইরে হুল্লোড় এর ওয়েবসাইটে প্রবেশ করতে চাই,
          তবে কি হবে?</label
        ><br />
        হুল্লোড় বাংলাদেশে www.hullor.io প্রকাশ করেছে।<br />
        অনুগ্রহ করে মনে রাখবেন, হুল্লোড় এ কিছু কুকি ব্যবহার করে এবং সেগুলোর
        মাধ্যমে আন্তর্জাতিক ব্যবহারকারীদের সম্পর্কে তথ্য সংগ্রহ করতে পারে –
        কুকির বিষয়ে বিস্তারিত জানতে অনুগ্রহ করে ১৩ নম্বর পয়েন্টটি লক্ষ্য করুন।
      </p>
      <p>
        <label class="lblbold"
          >১২. হুল্লোড় এর তথ্যের গোপনীয়তার নীতিমালায় পরিবর্তন</label
        ><br />
        আপনার তথ্যের গোপনীয়তার এই নীতিমালাগুলো বিভিন্ন সময়ে আপডেট করা হতে
        পারে। তাই হুল্লোড় ব্যক্তিগত তথ্য প্রদানের সময় আপনি প্রতিবার সেগুলো দেখে
        নিতে পারেন। সর্বশেষ সংশোধনের তারিখটি এই ওয়েবপেইজে প্রদর্শিত হবে।
      </p>
      <p>
        <label
          >১৩. কুকি বিষয়ক নীতি – হুল্লোড় আপনার যে তথ্যগুলো সংগ্রহ করে</label
        ><br />
        <label class="lblbold">ক. কুকি কি?</label><br />
        কুকি হলো অল্প কিছু তথ্যের সমষ্টি। কুকি ওয়েবসাইটের কম্পিউটার থেকে আপনার
        কম্পিউটার বা মোবাইল ফোনের (এখানে একটি ‘ডিভাইস’ বোঝানো হচ্ছে) ব্রাউজারে
        প্রায়ই একটি মৌলিক শনাক্তকারী উপাদান অন্তর্ভূক্ত করে এবং আপনার ডিভাইসের
        হার্ডড্রাউভে সেটি জমা করে রাখে। আপনার ব্রাউজারের অনুমতি পেলে প্রতিটি
        ওয়েবসাইট আপনার ব্রাউজারে তার কুকি পাঠাতে পারে। কিন্তু, (আপনার গোপনীয়তা
        রক্ষার জন্য) আপনার ব্রাউজার শুধুমাত্র একটি ওয়েবসাইটকে এই অনুমতি দিয়ে
        থাকে, অন্য সাইটগুলোকে নয়। অনলাইনের স্বাভাবিক কার্যকারিতা বজায় রাখতে
        অনেক ওয়েবসাইট যেকোনো সদস্যের যেকোনো ওয়েবপেইজ ভিজিট করার সময় এমনটি করে
        থাকে।<br />

        অনলাইনে আপনার পছন্দের তথ্যসমূহ কুকিগুলো ধারণ করে রাখে। কম্পিউটারে সকল
        কুকি গ্রহণ করা, বাছাই করা বা বাতিল করার সুযোগ ব্যবহারকারীদের রয়েছে।
        বাতিল করার অর্থ হলো, ব্যবহারকারীরা ওয়েবসাইটের বিশেষ কিছু সেবা থেকে
        বঞ্চিত হবেন এবং এজন্য হুল্লোড় এর সবগুলি সুবিধা আপনি নাও পেতে পারেন।
        যেহেতু প্রতিটি ব্রাউজার ভিন্নভাবে কাজ করে, তাই সঠিকভাবে কুকি
        নিয়ন্ত্রণের জন্য আপনার ব্রাউজারের ‘হেল্প’ মেনুতে গিয়ে এ বিষয়ে ভালভাবে
        জেনে নিন।<br />

        আপনি যখন হুল্লোড় এর কোনো পাতা ভিজিট করেন, তখন কুকিসহ সেই পাতাটি আপনার
        ডিভাইসে ডাউনলোড হয়ে যায়। অনেক ওয়েবসাইট এমনটি করে, কারণ কুকিগুলো
        ওয়েবসাইট পাবলিশারদের বেশকিছু সুবিধা দিয়ে থাকে, যেমনঃ কোনো ডিভাইস (এবং
        সম্ভবত এর ব্যবহারকারী) কোনো ওয়েবসাইট ভিজিট করেছে কিনা সে বিষয়ে জানাতে
        পারে। পুনরায় ভিজিট করার সময় দেখা বা খোঁজার জন্য পর্যবেক্ষণের মাধ্যমে
        এমনটি হয়, সর্বশেষ ভিজিটের সময় কুকি সেই সাইটে থেকে যায়।<br />

        <label class="lblbold">খ. হুল্লোড় কীভাবে কুকি ব্যবহার করে? </label
        ><br />
        ব্যবহারকারীদের প্রোফাইল পর্যবেক্ষণ করতে এবং ব্যবহারকারী হিসেবে আপনার
        অভিজ্ঞতাকে আরও সমৃদ্ধ করতে কুকির মাধ্যমে সংগৃহীত তথ্যগুলো আমাদের সাহায্য
        করে। যেমনঃ পূর্বে কখনও ভিজিট করার সময় আপনি যদি ওয়েবসাইটের নির্দিষ্ট
        কোনো পাতায় যান, আমরা কুকির মাধ্যমে সেটা জানতে পারব এবং আপনার দ্বিতীয়
        বার বা তার পরবর্তী ভিজিটের সময় সেই পাতার তথ্যগুলো বিশেষভাবে তুলে
        ধরব।<br />

        গবেষণার কাজের জন্য হুল্লোড় এর কিছু প্রতিষ্ঠান রয়েছে। আমাদের জন্য তারা
        কুকি, লগ ফাইলের তথ্য এবং আমাদের ওয়েবসাইটে থাকা বিভিন্ন কোড ব্যবহার করে
        হুল্লোড় সাইটের ভিজিটরদের তথ্য সংগ্রহ করে। ব্যবহারকারীদের জন্য প্রদত্ত
        সকল সেবার মান উন্নয়নের লক্ষ্যে হুল্লোড় সে ধরনের তথ্যগুলো ব্যবহার করে
        থাকে। সেক্ষেত্রে হুল্লোড় নিশ্চিত করবে যে, তৃতীয় পক্ষসমূহ কোনো তথ্য
        তাদের নিজস্ব ব্যবসা বা অন্য কোনো প্রয়োজনে ব্যবহার করবে না।
      </p>
      <p>
        <label class="lblbold"
          >১৪. তথ্য গোপনীয়তার এই নীতিমালার বিষয়ে হুল্লোড় এর সঙ্গে
          যোগাযোগ</label
        ><br />
        তথ্য গোপনীয়তার এই নীতিমালার বিষয়ে আপনার কোনো প্রশ্ন থাকলে বা মতামত
        জানাতে চাইলে অনুগ্রহ করে এই ঠিকানায় যোগাযোগ করুন:<br />

        কর্পোরেট অফিস<br />
        মোম্যাজিক বাংলাদেশ লিমিটেড<br />
        র‌্যাংগস বেবিলনিয়া, ২৪৬ বীর উত্তম মীর শওকত সড়ক<br />
        তেজগাঁও গুলশান লিঙ্ক রোড, ঢাকা ১২১২।
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-main {
  display: block;
  margin: 0 auto;
}

.title-vdo-01 {
  padding: 15px 15px 10px 15px;
  font-family: inherit;
  font-weight: 600;
  font-size: 24px;
  color: aliceblue;
  line-height: 1.2;
  /* background: #232f3e; */
}

.abt-us {
  margin: 5px 15px 25px 15px;
  display: block;
  padding-bottom: 40px;
}

.abt-us p {
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  color: aliceblue;
  line-height: 2;
  margin-bottom: 20px;
}
</style>
